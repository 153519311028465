* {
  text-align: center;
}
.header-nav {
  background-color: #202641 !important;
  color: #d8b671 !important;
}
.header-nav a {
  color: #d8b671 !important;
  padding: 2px 20px !important;
}

.homeBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/homebg.jpg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainTitle h1 {
  color: white;
  font-size: 100px;
  text-align: left;
}
.mainTitle p {
  color: white;
  font-size: 25px;
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
}

.mainTitle {
  background-color: rgba(12, 60, 96, 0.5);
  height: 100vh;
}
/* .mainTitleHome {
  background-color: rgba(12, 60, 96, 0.5);
  height: 113vh;
  position: absolute;
  top: 65px;
} */
.mission h6 {
  font-size: 30px;
}
.servBtn {
  border-radius: unset;
  background-color: #0c3c60 !important;
  color: white;
  border: unset !important;
  position: absolute;
  bottom: -10%;
  left: 30%;
}
.servBtn a {
  color: white;
}
.desc1 {
  text-align: justify;
  padding: 10px;
}
.serv {
  background-color: #f5f5f5;
}

.teamImg {
  /* width: 185px;
  height: 180px; */
  /* border-radius: 50%; */
}
.desc2 {
  text-align: justify;
}

.card-title {
  font-size: 18px;
  color: #800000;
  font-weight: bold;
}
.p2 {
  text-align: justify;
  font-size: 14px;
}
.p4 {
  font-size: 13px;
  text-align: justify;
}

/**************************************************************/
.line {
  background-color: #0c3c60;
  color: white;
}

.footer {
  text-align: justify;
}
.footer i {
  color: #0c3c60;
  font-size: 20px;
}

.pf1,
.pf3 {
  font-size: 18px;
}

/********************************************************************************************************/

.detailsBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/bg2.jpg");
  height: 50vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/************************************************************************/

.dropdown-menu,
.dropdown-item:hover {
  background-color: #202641 !important;
}

/*********************************************************************/

.aboutBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/aboutBg.jpeg");
  height: 70vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.accaBG {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acca2.jpeg");
  height: 60vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutSec {
  /* background-image: linear-gradient(
      rgba(12, 60, 96, 0.1),
      rgba(12, 60, 96, 0.1)
    ),
    linear-gradient(rgba(12, 60, 96, 0.1), rgba(12, 60, 96, 0.1)),
    url("./imgs/img4.jpg"); */
  background-color: #202641;
}

.pAbout {
  text-align: justify;
  font-size: 18px;
  color: #d8b671;
}
.aboutSec h4,
.aboutSec h2 {
  color: #d8b671;
}

/***************************************/

.myDrop {
  position: relative;
}

.sun .myDrop {
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.list1 {
  list-style-type: none;
  font-size: 18px;
  text-align: justify;
}
/* .rowProgram {
  padding-left: 78px;
}

@media only screen and (max-width: 600px) {
  .rowProgram {
    padding-left: unset;
  }
} */

.mainAcca {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/mainACCA.jpg");
  height: 70vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tailoredMain {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acc2.jpeg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cpdBg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/img19.jpeg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.interAC {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/img16.jpg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.interAudit {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/audit.jpeg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.interFinance {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acc1.jpeg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.accaPro {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acca1.jpeg");
  height: 60vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ifrs {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acca2.jpeg");
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.team {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/team2.jpeg");
  height: 70vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.imgDiv {
  margin: 80px 0px 0px 15px;
}
.lineee {
  width: 170px;
  height: 3px;
  background-color: #d8b671;
  margin-left: initial;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("./imgs/menu.png");
}
.mainTitle2 {
  background-color: rgba(12, 60, 96, 0.5);
  height: 100vh;
}
.mainTitle2 h1 {
  color: white;
  font-size: 80px;
  text-align: left;
}
.mainTitle2 p {
  color: white;
  font-size: 25px;
  text-align: left;
  font-weight: bold;
  margin-top: 40px;
}

.aboutColored {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/qualiACCA.jpg");
  height: 60vh;
  position: relative;
  /* background-position: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
}
.about1 h5,
.about1 p,
.about1 a,
.about2 h5,
.about2 p,
.about2 a,
.about3 li,
.about3 h5 {
  text-align: left;
  color: white;
}
.about1 {
  background-color: #0d7889;
  opacity: 0.7;
}
.about2 {
  background-color: #d8b671;
  opacity: 0.7;
}

.about11 {
  background-color: rgba(12, 60, 96, 0.5);
  opacity: 0.7;
}
.about22 {
  background-color: rgba(12, 60, 96, 0.5);
  opacity: 0.7;
}

.about3 {
  background-color: #1b755a;
}

.about4 {
  /* background-color: #daeeee; */
  text-align: left;
}
.about4 p,
.about4 h5 {
  text-align: left;
  color: #d8b671;
}

.check {
  position: absolute;
  background-color: #d8b671;
  left: 0px;
  bottom: -16px;
  right: 0px;
  padding: 5px;
  color: white;
  text-align: center !important;
}
.check a {
  color: white !important;
}

.check a:hover {
  color: #202641 !important;
  font-weight: bold;
}

/**************************program cards css*****************************/
.colCards {
  padding: 45px;
}
.programBox {
  border-radius: var(--card-border-radius, 10px 10px 0 0);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin-right: 15px;
  background-color: #202641;
  color: #d8b671;
}
.programBox:hover {
  box-shadow: 0 19px 26px rgb(0 0 0 / 20%);
}
.programBox h5 {
  text-align: center;
  color: #d8b671;
  margin-bottom: 15px;
}
.programBox p {
  text-align: justify;
  font-size: 16px;
}

.programBox1 {
  border-radius: var(--card-border-radius, 10px 10px 0 0);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin-right: 15px;
  background-color: #202641;
  color: #d8b671;
  height: 250px;
}
.programBox1:hover {
  box-shadow: 0 19px 26px rgb(0 0 0 / 20%);
}
.programBox1 h5 {
  text-align: center;
  color: #d8b671;
  margin-bottom: 15px;
}
.programBox1 p {
  text-align: justify;
  font-size: 16px;
}

.RowProgram {
  flex-wrap: nowrap !important;
}
.fa-check {
  color: #d8b671 !important;
}
.about1 p,
.about2 p {
  font-size: 15px;
}

.rowProgram {
  flex-wrap: nowrap !important;
  /* overflow: hidden !important; */
}

.aboutColored3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/img5.jpg");
  height: 70vh;
  /* position: relative; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutColored4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/pm2.jpeg");
  /* height: 60vh; */
  /* position: relative; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 200px;
}
.package {
  /* background-color: #e3dfc8; */
}

.aboutColored5 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./imgs/acca2.jpeg");
  /* height: 60vh; */
  /* position: relative; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
}

.colPadding {
  padding-left: 15px;
}

.mainTitle3 {
  background-color: #202641;
  opacity: 0.8;
  height: 100vh;
}

.mainTitle3 h5 {
  color: white;
  text-align: left;
  color: #d8b671;
}
.mainTitle3 p {
  color: #d8b671;
  font-size: 18px;
  text-align: left;
  text-align: left;
  margin-top: 15px;
}
.myFooter {
  margin-top: 50px;
  background-color: #202641;
  color: #d8b671;
}

.myFooter i,
.myFooter a {
  color: #d8b671 !important;
  text-decoration: unset !important;
  font-size: 22px;
}
.myFooter i:hover {
  color: #fff !important;
  font-size: 28px !important;
  transition: ease-in;
}

.homeBtn {
  background-color: #202641 !important;
  box-shadow: unset !important;
  border: solid 2px #d8b671;
  border-radius: 25px;
}
.homeBtn a {
  color: #d8b671 !important;
  font-weight: bold;
}
.homeBtn:hover {
  background-color: #d8b671 !important;
  box-shadow: unset !important;
  border: solid 2px #202641;
  border-radius: 25px;
}
.homeBtn a:hover {
  color: #202641 !important;
}

.homeAbout {
  background-color: #202641;
  color: #d8b671;
  padding: 30px;
  max-width: 1254px !important;
  border-radius: 15px;
}
.homeAbout p {
  text-align: left;
  font-size: 18px;
}
.homeAbout .fa-chalkboard-teacher,
.teamHome .fa-users {
  color: #d8b671;
  font-size: 120px !important;
}
.homeAbout .fa-chalkboard-teacher:hover,
.teamHome .fa-users:hover {
  font-size: 130px !important;
}

.teamHome {
  background-color: #202641;
  color: #d8b671;
  padding: 30px;
  max-width: 1266px !important;
  border-radius: 15px;
}
.teamHome p {
  text-align: justify;
  font-size: 18px;
}

.pmDiv {
  background-color: #202641;
}
.pmDiv h4 {
  color: #d8b671;
}

.fa-graduation-cap {
  font-size: 70px;
  margin-bottom: 15px;
}

.teamBio p,
.teamBio .lineee {
  text-align: justify;
}

.teamDiv {
  background-color: #202641;
  color: #d8b671;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 15px;
  max-width: 1200px !important;
}
.teamT1 {
  font-weight: 700;
  text-align: center;
  font-size: 24px;
  margin-bottom: 0rem !important;
}

.teamT2 {
  font-weight: 700;
  font-size: 20px;
  padding-top: 15px;
}
.teamT3 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0rem !important;
}

.arSyllabs p {
  text-align: right !important;
}

@media only screen and (max-width: 600px) {
  .mainTitle h1 {
    color: white;
    font-size: 60px;
    text-align: left;
  }
  .mainTitle p {
    color: white;
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-top: 40px;
  }
  .mainTitle2 h1 {
    color: white;
    font-size: 47px;
    text-align: left;
  }
  .mainTitle2 p {
    color: white;
    font-size: 25px;
    text-align: left;
    font-weight: bold;
    margin-top: 40px;
  }
  .homeBg,
  .mainTitle,
  .mainTitle2,
  .team,
  .ifrs,
  .accaPro,
  .mainAcca,
  .tailoredMain,
  .cpdBg,
  .interAC,
  .interAudit,
  .interFinance,
  .aboutBg {
    height: 70vh;
  }
  ul {
    padding-left: unset !important;
  }
  .pAbout {
    font-size: 16px;
  }
  .rowProgram {
    flex-wrap: wrap !important;
    overflow: unset !important;
  }
  .colPadding {
    padding-left: 5px;
  }
  .mainTitle3,
  .accaBG {
    height: 50vh;
  }
  .mainTitle3 p {
    font-size: 15px;
  }
  .programBox1 {
    height: 300px;
    margin-right: unset;
  }
  .programBox {
    /* height: 300px; */
    margin-right: unset;
  }
  .colCards {
    padding: unset;
  }
  .imgDiv {
    margin: unset !important;
  }
  .teamT3 {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .customContainer,
  .customContainer-lg,
  .customContainer-md,
  .customContainer-sm,
  .customContainer-xl {
    max-width: 1255px !important;
  }
}
